<template lang="html">
    <MyneoPrivacyPolicySection />
  </template>
  
  <script>
  import MyneoPrivacyPolicySection from "../components/privacypolicy/myneo/MyneoPrivacyPolicySection.vue";
  
  export default {
    components: {
      MyneoPrivacyPolicySection,
    },
    created() {
      // this.$store.commit("muttHideHeader", "wa");
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .eula {
    text-align: justify;
  
    b {
      font-weight: bold;
    }
  }
  .inner {
    @media screen and (max-width: 599px) {
      margin: 20px 0 40px;
    }
  }
  </style>
  